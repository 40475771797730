import "../../../.yarn/__virtual__/@vanilla-extract-webpack-plugin-virtual-45dcc1da16/0/cache/@vanilla-extract-webpack-plugin-npm-2.3.18-36e80ce7b3-7430ddfe6d.zip/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2FuiPrimitives%2Fbase%2Flayers.css.tsx.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA5WWUW7DIAxA%2F3eKnSAX6E%2B1apoiTdPUGxDipmgEI0PSZqefknUrVMGQT5zHswMOYa%2FFBPSsoRNyOoIDv3va%2F8YoGgXEEUagxyfBtPnpexxshIP%2FwaA%2BSfXKqxFcEKwlmmB4wN6iUz5gGo3y6wPbIORAeoXmHjihHO4jHIEuFEnC7FWyrsoS2sQkT8I4NadNSystJhw842aJQFG5syBo8yYWDIVWdPBGqkTJo6GUEH0ZKQW1hel5NHonpbmNzGOhTBAJ05X4WDLaREmotWg0HAS1icaKd7NkRpgCpRzsVGDmwFD4XZsWrgVCDlxrvNpDv6H50vhaWxXK83gob7BkHZJUtAjInR3xArBo1F6DBepItUe8FDVXno%2F1zSZ5hl6t%2FIB66M2m4tkpK%2FVvSZGfsCTwk8WOhD2zH16OelBVHq7%2BZf7ZlUlz%2FJq%2BNloZ9ojbMGFJ0EOrBCdkgLugUr3gj94sFsjc2OVVSSisSvJfbI56UOVXv4xdtMp4ICHnCGfMYoNXWvlEi94eVvON6qTxwlMnnfof%2FBEWl3peRzD%2B4fYW38puIbzufgDLZnpNrAoAAA%3D%3D%22%7D"
import "../../../.yarn/__virtual__/@vanilla-extract-webpack-plugin-virtual-45dcc1da16/0/cache/@vanilla-extract-webpack-plugin-npm-2.3.18-36e80ce7b3-7430ddfe6d.zip/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2FuiPrimitives%2Fcolor%2Fcolors.css.tsx.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA42R0W6CMBSG73mKc0PikpK0tEWKN77JUhB1s9pZtyXL4rsvpaJUTgYXTWj65fz%2F%2BVgb%2FdM6qPWlXSXrxwV%2BE4DKWfvZfQFk2SvbitqcDa1gfzELSoCm%2FrysngAWgJxSAkqlBDgbQ%2FkQKlMCeT6GeIC4hzhPCQgkTkR9MEL2YwijnuFyzBSBEf9UXgaEcV9Z%2BsrInDJq4%2FNGiJoSqCOgRIh6juJmjuLN3Q2opfeH7NQ%2BmCBQIGnbaYG7OQL30wLf%2BsVyAoWfo5Cw9x7iBIrCe0Sgw20z1mkmoJA088Rg%2F%2BMYGMkJqC4LMX0aML60RJa3sUSFIB%2BRH4w4x8S9bq2bw87Zr9Mma6yxroJv7RYD8zcOfaTd4zW5%2FgEgDtwdMgQAAA%3D%3D%22%7D"
import "../../../.yarn/__virtual__/@vanilla-extract-webpack-plugin-virtual-45dcc1da16/0/cache/@vanilla-extract-webpack-plugin-npm-2.3.18-36e80ce7b3-7430ddfe6d.zip/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2FuiPrimitives%2Fbase%2Ffocus.css.tsx.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA%2BVXbW%2BbQAz%2Bzq%2Bw%2BqGC6aiSNu20i1Tlf0zTdAQjbqN37M4kZBP%2FfRqvgTZMbWHSEiEh8Nnm%2FNg89m0ScUADkd5m9ibQ%2BdrZ9CTC4trZ2CxNtSELShNYTHBL2ri81PF30sogQQ9%2BOQAvWBcDlzovNfk%2BRoOuYBBkRFoxsNnTkzAHBlKlGTEgzEkYFKz%2BJIPPJAKpQsy%2FcKXJDXR48Lz%2BNuARbr4uV4u75bfvd6XWZB%2BqIgTQGSVSoW%2FpkCCHUNgYw3VvaS9DijncpnlfrqPIInHw25VA576NRaj3HKSySLAor9s0h50wru830SxZK4hWQfIjiT2v732rE234wGwxNPtUmRUOHCM1UzbOFrFVjRiPhXUf56rmc665h36oXESEhh2v%2F4G2fb0fIFPp1%2FBstSJUxOHqan1GeAGk2kqSWnEQgdVJRljJy21wWDRaUhEaH3eoyHJQWmGD9yvIe3JS%2FgdkPJrq08keS%2Fc0CX9zyovyPhs1Xwpu8xL05dTfQxvodRV6Q9TXJbIDeu7T8l%2FhGQNoHKKpQHozTL2204lOEfZz0h4j7i4PRUnjwxla2Gq0arP0ceL6ZvBhxOMYvXRbqougQ8RgIkju6gh%2F%2BqULDsumVU0XQ%2BWo4wKYkAAY1JQyW2sbUHVdoP22Ph1WR3vvfvqjOez%2B6Pm%2FA3K288iLw2FFQKv5x%2BjiXWfyyYe9Syihqfv9iXb%2F%2BhJ6V6MvnOI3na8ScAMSAAA%3D%22%7D"
export var focusBackground = 'var(--_14031jk1)';
export var focusBox = '_14031jk3';
export var focusForeground = 'var(--_14031jk0)';
export var focusMain = '_14031jk6';
export var focusMainTarget = '_14031jk5';
export var focusShadow = 'var(--_14031jk2)';
export var focusWithZIndex = '_14031jk7';
export var focusWrapper = '_14031jk4';